/* eslint-disable @typescript-eslint/no-unused-vars */
import {
	Box, Card, Flex, Grid, GridItem, Heading, Skeleton, Text, Image, Link,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { MaethrilIcon } from "../components/Icons";
import { IPagination, ISearchBlog } from "../types/Pagination";
import { IBlogCard } from "../types/Blog";
import configData from "../config.json";
import ReadMoreImage from "../images/ReadMoreImage.png";
import HeroSummonerIconBlur from "../images/HeroSummonerIconBlur.png";

const HomePage: React.FC = () =>
{
	const [loadingBlogs, setLoadingBlogs] = useState<boolean>(true);
	const [errorBlogs, setErrorBlogs] = useState<boolean>(false);
	const [blogs, setBlogs] = useState<IPagination<IBlogCard>>({
		totalCount: 0,
		items: [],
	});

	const skeletonItems = [0, 1, 2, 3];

	const getBlogs = async (search: ISearchBlog) =>
	{
		const resetBlogs = search.index === 0 && blogs.items.length > 0;

		axios({
			method: "POST",
			url: configData.BLOGS_URL,
			data: {
				index: search.index,
				amount: search.amount,
				searchValue: search.searchValue,
				tags: search.tags,
			},
		})
			.then((response) =>
			{
				setBlogs({
					totalCount: response.data.totalCount,
					items: resetBlogs ? response.data.items : blogs.items.concat(response.data.items),
				});
			})
			.catch(() => setErrorBlogs(true))
			.finally(() =>
			{
				setLoadingBlogs(false);
			});
	};

	useEffect(() =>
	{
		getBlogs({
			index: 0,
			amount: 3,
			searchValue: "",
			tags: [],
		});
	}, []);

	return (
		<Box>
			<Box
				height="500px"
				bg="gray.100"
				bgGradient="linear(blue.100 0%, gray.100 100%)">
				<Flex
					h="100%"
					mx="auto"
					maxW="1200px"
					minW="280px"
					px="20px"
					direction="column"
					alignItems="center"
					justifyContent="center">
					<MaethrilIcon
						color="white"
						height={{ base: "150px", md: "200px" }}
						width={{ base: "150px", md: "200px" }} />
					<Text
						fontSize="lg"
						textAlign="center"
						maxWidth="480px">
						Our dream is to develop games that leave you with
						a sense of fulfillment,
						having spent your time meaningfully.
					</Text>
				</Flex>
			</Box>
			<Flex
				maxW="1200px"
				minW="280px"
				mx="auto"
				px="20px"
				direction="column">
				<Flex
					py="32px"
					direction="column">
					<Heading>Latest news</Heading>
					{
						!errorBlogs ? (
							<Grid
								mt="12px"
								templateColumns={{ md: "repeat(2, 1fr)" }}
								gap={6}>
								{
									loadingBlogs
										? skeletonItems.map((item) => (
											<GridItem
												key={item}
												w="100%"
												h={{ base: "164px", md: "224px" }}>
												<Skeleton height="100%" />
											</GridItem>
										))
										: (
											<>
												{
													blogs.items.map((blog) => (
														<GridItem
															key={blog.id}
															w="100%"
															h={{ base: "164px", md: "224px" }}>
															<Link
																href={`https://blog.maethril.net/blog/${blog.id}`}
																target="_blank"
																opacity={0.9}
																transition="200ms ease"
																_hover={{
																	textDecoration: "none",
																	opacity: 1,
																}}>
																<Card
																	direction="row"
																	variant="outline"
																	height="100%"
																	p="12px"
																	borderColor="gray.300 20%"
																	transition="200ms ease"
																	_hover={{
																		borderColor: "gray.300",
																	}}>
																	<Image
																		src={blog.thumbnail}
																		alt={blog.title}
																		height={{ base: 140, md: 200 }}
																		width={{ base: 140, md: 200 }} />
																	<Flex
																		ml="12px"
																		direction="column">
																		<Heading
																			fontSize="20px"
																			fontWeight="400">
																			{blog.title}
																		</Heading>
																		<Text
																			opacity={0.6}>
																			{blog.publishedDate}
																		</Text>
																	</Flex>
																</Card>
															</Link>
														</GridItem>
													))
												}
												<GridItem
													w="100%"
													h={{ base: "164px", md: "224px" }}>
													<Link
														href="https://blog.maethril.net/"
														target="_blank"
														opacity={0.9}
														transition="200ms ease"
														_hover={{
															textDecoration: "none",
															opacity: 1,
														}}>
														<Card
															direction="row"
															variant="outline"
															height="100%"
															p="12px"
															borderColor="gray.300 20%"
															transition="200ms ease"
															_hover={{
																borderColor: "gray.300",
															}}>
															<Image
																src={ReadMoreImage}
																alt="ReadMore"
																height={{ base: 140, md: 200 }}
																width={{ base: 140, md: 200 }} />
															<Flex
																ml="12px"
																direction="column">
																<Heading
																	fontSize="20px"
																	fontWeight="400">
																	Maethril Blog
																</Heading>
																<Text
																	opacity={0.6}>
																	Read about Maethril - from game updates to working with us!
																</Text>
															</Flex>
														</Card>
													</Link>
												</GridItem>
											</>
										)
								}
							</Grid>
						) : (
							<Flex
								mt={{ base: "12px", md: "24px" }}
								direction={{ base: "column", md: "column" }}>
								<Link
									href="https://blog.maethril.net/"
									target="_blank"
									minWidth="280px"
									width="100%"
									maxWidth="720px"
									mt={{ base: "20px", md: "unset" }}
									opacity={0.9}
									transition="200ms ease"
									_hover={{
										textDecoration: "none",
										opacity: 1,
									}}>
									<Card
										direction="row"
										variant="outline"
										height="100%"
										p="12px"
										borderColor="gray.300 20%"
										transition="200ms ease"
										_hover={{
											borderColor: "gray.300",
										}}>
										<Image
											src={ReadMoreImage}
											alt="ReadMore"
											height={{ base: 140, md: 200 }}
											width={{ base: 140, md: 200 }} />
										<Flex
											ml="12px"
											direction="column">
											<Heading
												fontSize="20px"
												fontWeight="400">
												Maethril Blog
											</Heading>
											<Text
												opacity={0.6}>
												Read about Maethril - from game updates to working with us!
											</Text>
										</Flex>
									</Card>
								</Link>
							</Flex>
						)
					}
				</Flex>
				<Flex
					py="32px"
					mb="80px"
					direction="column">
					<Heading>Our games</Heading>
					<Flex
						direction={{ base: "column", md: "row" }}
						mt="20px">
						<Image
							src={HeroSummonerIconBlur}
							alt="Hero Summoner - Turn-Based RPG Icon"
							height={{ base: 200 }}
							width={{ base: 200 }} />
						<Flex
							direction="column"
							mt={{ base: "12px", md: "unset" }}
							ml={{ base: "unset", md: "20px" }}>
							<Heading
								fontSize={{ base: "22px", md: "28px" }}
								fontWeight={500}>
								Hero Summoner - Turn-based RPG
							</Heading>
							<Text opacity={0.6}>
								Planned for 2023
							</Text>
						</Flex>
					</Flex>
				</Flex>
				{
				/*
				<Flex
					py="32px"
					direction="column">
					<Heading>About</Heading>
				</Flex>
				*/
				}

			</Flex>
		</Box>
	);
};

export default HomePage;
