import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import {
	Button, Flex, FormControl, FormLabel,
	Heading,
	Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter,
	ModalHeader, ModalOverlay, Stack, Switch, Text,
} from "@chakra-ui/react";

interface ICookieSettingsConsent
{
	isOpen: boolean;
	onClose: () => void;
	showMarketing?: boolean;
	showPersonalization?: boolean;
	showAnalytics?: boolean
}

const ManageCookiesModal: React.FC<ICookieSettingsConsent> = (props: ICookieSettingsConsent) =>
{
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [cookies, setCookie] = useCookies(["maethril_consent"]);

	const [marketing, setMarketing] = useState<boolean>(cookies["maethril_consent"]?.marketing);
	const [personalization, setPersonalization] = useState<boolean>(cookies["maethril_consent"]?.personalization);
	const [analytics, setAnalytics] = useState<boolean>(cookies["maethril_consent"]?.analytics);

	useEffect(() =>
	{
		setMarketing(cookies["maethril_consent"]?.marketing);
		setPersonalization(cookies["maethril_consent"]?.personalization);
		setAnalytics(cookies["maethril_consent"]?.analytics);
	}, [props.isOpen]);

	const handleAcceptAll = () =>
	{
		let cookie = {
			marketing: true,
			personalization: true,
			analytics: true,
		};

		let expiration = new Date();
		expiration.setDate(expiration.getDate() + 365);

		setCookie("maethril_consent", cookie, {
			path: "/",
			secure: true,
			sameSite: "lax",
			expires: expiration,
		});

		props.onClose();
	};

	function handleSave()
	{
		let cookie = {
			marketing,
			personalization,
			analytics,
		};

		let expiration = new Date();
		expiration.setDate(expiration.getDate() + 365);

		setCookie("maethril_consent", cookie, {
			path: "/",
			secure: true,
			sameSite: "lax",
			expires: expiration,
		});

		props.onClose();
	}

	return (
		<Modal
			isOpen={props.isOpen}
			onClose={props.onClose}
			isCentered
			size={{ base: "full", sm: "md" }}>
			<ModalOverlay />
			<ModalContent>
				<FormControl>
					<ModalHeader>Cookie settings</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing={6}>
							{
								props.showMarketing ? (
									<Flex direction="column">
										<Flex
											justifyContent="space-between"
											alignItems="center"
											w="100%"
											mb="2">
											<FormLabel m="0" htmlFor="marketingCookies">
												<Heading fontSize="22px">Marketing Cookies</Heading>
											</FormLabel>
											<Switch
												id="marketingCookies"
												isChecked={marketing}
												onChange={() => setMarketing(!marketing)}
												mr="8px" />
										</Flex>
										<Text
											fontSize="15px"
											opacity="0.8">
											These cookies are placed by us or third parties
											to help us measure effectiveness
											of our marketing operations and tailor advertising to you.
											By accepting these cookies,
											third parties may receive data about your interaction with this website
											and identifiers of your browser and device.
										</Text>
									</Flex>
								) : null
							}
							{
								props.showPersonalization ? (
									<Flex direction="column">
										<Flex
											justifyContent="space-between"
											alignItems="center"
											w="100%"
											mb="2">
											<FormLabel m="0" htmlFor="personalizationCookies">
												<Heading fontSize="22px">Personalization Cookies</Heading>
											</FormLabel>
											<Switch
												id="personalizationCookies"
												isChecked={personalization}
												onChange={() => setPersonalization(!personalization)}
												mr="8px" />
										</Flex>
										<Text
											fontSize="15px"
											opacity="0.8">
											These cookies are placed by us so we can remember your personal preferences
											and tailor the best experience for you.
										</Text>
									</Flex>
								) : null
							}
							{
								props.showAnalytics ? (
									<Flex direction="column">
										<Flex
											justifyContent="space-between"
											alignItems="center"
											w="100%"
											mb="2">
											<FormLabel m="0" htmlFor="analyticsCookies">
												<Heading fontSize="22px">Analytics Cookies</Heading>
											</FormLabel>
											<Switch
												id="analyticsCookies"
												isChecked={analytics}
												onChange={() => setAnalytics(!analytics)}
												mr="8px" />
										</Flex>
										<Text
											fontSize="15px"
											opacity="0.8">
											These cookies help us understand how visitors interact with the website.
											They inform of number of visits, traffic sources and time
											spent on the website so that we can measure and improve our site.
										</Text>
									</Flex>
								) : null
							}
							<Flex direction="column">
								<Flex
									justifyContent="space-between"
									alignItems="center"
									w="100%"
									mb="2">
									<FormLabel m="0" htmlFor="analyticsCookies">
										<Heading fontSize="22px">Necessary Cookie</Heading>
									</FormLabel>
									<Text>Always Active</Text>
								</Flex>
								<Text
									fontSize="15px"
									opacity="0.8">
									These cookies are necessary for the website to function.
									They enable you to use the website features and
									cannot be switched off in our systems.
								</Text>
							</Flex>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button
							onClick={handleAcceptAll}
							mr="3"
							bg="blue.400"
							transition="200ms ease"
							_hover={{ bg: "blue.300" }}>
							Accept all
						</Button>
						<Button
							onClick={handleSave}
							bg="blue.400"
							transition="200ms ease"
							_hover={{ bg: "blue.300" }}>
							Save
						</Button>
					</ModalFooter>
				</FormControl>
			</ModalContent>
		</Modal>
	);
};

export default ManageCookiesModal;
