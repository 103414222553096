import {
	Box, Flex, HStack, IconButton, Link,
	Stack, useDisclosure, Text, Divider, Image, Heading,
} from "@chakra-ui/react";
import React from "react";
import { Link as RouterLink, useLocation, useOutlet } from "react-router-dom";
import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useCookies } from "react-cookie";
import { AppRoute, routes } from "../App";
import {
	FacebookIcon, InstagramIcon, LinkedInIcon, TwitterIcon,
} from "../components/Icons";
import MaethrilLogoSmall from "../images/MaethrilLogoSmall.png";
import GooglePlayCTA from "../images/GooglePlayCTA.png";
import AppStoreCTA from "../images/AppStoreCTA.png";
import CookieConsent from "../components/CookieConsent";
import ManageCookiesModal from "../components/ManageCookiesModal";

const MainLayout: React.FC = () =>
{
	const [cookies] = useCookies(["maethril_consent"]);
	const {
		isOpen: isCookieSettingsOpen,
		onOpen: onCookieSettingsOpen,
		onClose: onCookieSettingsClose,
	} = useDisclosure();

	const currentOutlet = useOutlet();
	const location = useLocation();

	const { isOpen, onOpen, onClose } = useDisclosure();

	const navRoutes: AppRoute[] = [
		...routes.filter((x) => x.name),
		{
			path: "https://blog.maethril.net/", name: "Blog",
		},
	];

	return (
		<Flex
			direction="column"
			minHeight="100vh">
			{
				!cookies["maethril_consent"]
					? (
						<CookieConsent
							showMarketing
							showPersonalization
							showAnalytics />
					) : null
			}
			<ManageCookiesModal
				isOpen={isCookieSettingsOpen}
				onClose={onCookieSettingsClose}
				showMarketing
				showPersonalization
				showAnalytics />
			<Box
				as="header"
				bg="black"
				color="white">
				<Flex
					h={16}
					alignItems="center"
					justifyContent="space-between"
					height="60px"
					minW="280px"
					maxW="1200px"
					px="20px"
					mx="auto">
					<RouterLink
						to="/">
						<HStack
							spacing={4}>
							<Image
								src={MaethrilLogoSmall}
								alt="Maethril Logo"
								height={7}
								width={7} />
							<Heading
								fontSize="22px">
								Maethril
							</Heading>
						</HStack>
					</RouterLink>
					<Flex>
						<HStack
							as="nav"
							display={{ base: "none", sm: "none", md: "flex" }}
							spacing={2}
							alignItems="right">
							{
								navRoutes
									.map((route) => (
										<Link
											key={route.name}
											as={RouterLink}
											to={route.path}
											px={2}
											py={1}
											fontWeight="700"
											opacity={location.pathname === route.path ? "1" : "0.5"}
											transition="200ms ease"
											_hover={{
												opacity: "1",
											}}>
											{route.name}
										</Link>
									))
							}
						</HStack>
						<IconButton
							size="md"
							icon={isOpen ? <CloseIcon mb="2px" /> : <HamburgerIcon mb="2px" />}
							aria-label="Open Menu"
							display={{ md: "none" }}
							onClick={isOpen ? onClose : onOpen}
							bg="unset"
							_hover={{
								bg: "gray.700",
							}} />
					</Flex>
				</Flex>
			</Box>
			<Box
				backgroundColor="white"
				minH="calc(100vh - 60px)"
				minW="100%">
				{
					isOpen ? (
						<Box
							p={4}
							display={{ md: "none" }}
							position="absolute"
							width="100%"
							bg="gray.900"
							color="white">
							<Stack
								as="nav"
								spacing={2}
								alignItems="right">
								{
									navRoutes
										.map((route) => (
											<Link
												key={route.name}
												as={RouterLink}
												to={route.path}
												onClick={onClose}
												px={2}
												py={1}
												fontWeight="700"
												opacity="0.8"
												transition="200ms ease"
												_hover={{
													opacity: "1",
												}}>
												{route.name}
											</Link>
										))
								}
							</Stack>
						</Box>
					) : null
				}
				{ currentOutlet }
			</Box>
			<Box
				color="white"
				bg="black"
				pt="20px"
				pb="80px">
				<Flex
					direction="column"
					minW="280px"
					maxW="1200px"
					px="20px"
					mx="auto">
					<Flex
						alignItems="center"
						justifyContent="space-between">
						<Flex direction="column">
							<Text fontSize="md" opacity="0.65">Follow us</Text>
							<HStack
								mt="4px"
								ml="-4px"
								spacing={0.2}>
								{
								/*
								<Link
									href="/"
									target="_blank"
									opacity="0.8"
									transition="200ms ease"
									_hover={{
										opacity: "1",
									}}>
									<YouTubeIcon
										height="42px"
										width="42px" />
								</Link>
								*/
								}
								<Link
									href="https://www.instagram.com/maethrilstudio/"
									target="_blank"
									opacity="0.8"
									transition="200ms ease"
									_hover={{
										opacity: "1",
									}}>
									<InstagramIcon
										height="42px"
										width="42px" />
								</Link>
								<Link
									href="https://www.facebook.com/MaethrilStudio"
									target="_blank"
									opacity="0.8"
									transition="200ms ease"
									_hover={{
										opacity: "1",
									}}>
									<FacebookIcon
										height="42px"
										width="42px" />
								</Link>
								<Link
									href="https://twitter.com/MaethrilStudio"
									target="_blank"
									opacity="0.8"
									transition="200ms ease"
									_hover={{
										opacity: "1",
									}}>
									<TwitterIcon
										height="42px"
										width="42px" />
								</Link>
								<Link
									href="https://www.linkedin.com/company/maethril-studio"
									target="_blank"
									opacity="0.8"
									transition="200ms ease"
									_hover={{
										opacity: "1",
									}}>
									<LinkedInIcon
										height="42px"
										width="42px" />
								</Link>
							</HStack>
						</Flex>
						<HStack
							display={{ base: "none", sm: "flex" }}
							spacing={4}
							opacity="0.3">
							<Image
								src={AppStoreCTA}
								alt="App Store "
								height={{ base: 10, md: 14 }} />
							<Image
								src={GooglePlayCTA}
								alt="Google Play"
								height={{ base: 10, md: 14 }} />
						</HStack>
					</Flex>
					<Divider display={{ base: "initial", sm: "none" }} my="22px" opacity="0.2" />
					<Flex
						display={{ base: "initial", sm: "none" }}
						direction="column"
						opacity="0.3">
						<Text fontSize="md" opacity="0.65">Download our games from</Text>
						<HStack
							mt="8px"
							spacing={4}>
							<Image
								src={AppStoreCTA}
								alt="App Store "
								height={{ base: 10, md: 14 }} />
							<Image
								src={GooglePlayCTA}
								alt="Google Play"
								height={{ base: 10, md: 14 }} />
						</HStack>
					</Flex>
					<Divider my="22px" opacity="0.2" />
					<Flex direction={{ base: "column", md: "row" }}>
						<Link
							href="https://blog.maethril.net/terms-of-service"
							target="_blank"
							fontWeight="600"
							opacity="0.8"
							mb={{ base: "8px", md: "unset" }}
							mr={{ base: "unset", md: "12px" }}
							transition="200ms ease"
							_hover={{
								opacity: "1",
							}}>
							Terms Of Service
						</Link>
						<Link
							href="https://blog.maethril.net/privacy-notice"
							target="_blank"
							fontWeight="600"
							opacity="0.8"
							mb={{ base: "8px", md: "unset" }}
							mr={{ base: "unset", md: "12px" }}
							transition="200ms ease"
							_hover={{
								opacity: "1",
							}}>
							Privacy Notice
						</Link>
						<Link
							href="https://blog.maethril.net/cookie-notice"
							target="_blank"
							fontWeight="600"
							opacity="0.8"
							mb={{ base: "8px", md: "unset" }}
							mr={{ base: "unset", md: "12px" }}
							transition="200ms ease"
							_hover={{
								opacity: "1",
							}}>
							Cookie Notice
						</Link>
						<Text
							onClick={onCookieSettingsOpen}
							fontWeight="600"
							opacity="0.8"
							cursor="pointer"
							transition="200ms ease"
							_hover={{
								opacity: "1",
							}}>
							Manage Cookies
						</Text>
					</Flex>
					<Text my="12px" opacity="0.2">
						© Maethril
						{" "}
						{new Date().getFullYear().toString()}
					</Text>
				</Flex>
			</Box>
		</Flex>
	);
};

export default MainLayout;
