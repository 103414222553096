import React, { Ref, createRef } from "react";
import {
	ChakraProvider, LightMode,
} from "@chakra-ui/react";
import { Navigate, RouterProvider, createBrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import HomePage from "./pages/HomePage";
import MainLayout from "./layouts/MainLayout";

type AppRoute = {
	path: string;
	name?: string;
	element?: React.ReactNode;
	nodeRef?: Ref<HTMLDivElement>;
}

const routes: AppRoute[] = [
	{
		path: "/", name: "Home", element: <HomePage />, nodeRef: createRef(),
	},
	/*
	{
		path: "/careers", name: "Careers", element: <CareersPage />, nodeRef: createRef(),
	},
	*/
	{
		path: "*", element: <Navigate to="/" replace />, nodeRef: createRef(),
	},
];

const router = createBrowserRouter([
	{
		path: "/",
		element: <MainLayout />,
		children: routes.map((route) => ({
			index: route.path === "/",
			path: route.path === "/" ? undefined : route.path,
			element: route.element,
		})),
	},
]);

const App = () => (
	<CookiesProvider>
		<ChakraProvider>
			<LightMode>
				<RouterProvider router={router} />
			</LightMode>
		</ChakraProvider>
	</CookiesProvider>
);

export default App;
export { routes };
export type { AppRoute };
