import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

import {
	Box,
	Button, Flex, FormControl, FormLabel, HStack, Switch, Text,
} from "@chakra-ui/react";

interface ICookieConsent
{
	showMarketing?: boolean;
	showPersonalization?: boolean;
	showAnalytics?: boolean
}

const CookieConsent: React.FC<ICookieConsent> = (props: ICookieConsent) =>
{
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [cookies, setCookie] = useCookies(["maethril_consent"]);

	const [marketing, setMarketing] = useState<boolean>(false);
	const [personalization, setPersonalization] = useState<boolean>(false);
	const [analytics, setAnalytics] = useState<boolean>(false);

	const handleAcceptAll = () =>
	{
		let cookie = {
			marketing: true,
			personalization: true,
			analytics: true,
		};

		let expiration = new Date();
		expiration.setDate(expiration.getDate() + 365);

		setCookie("maethril_consent", cookie, {
			path: "/",
			secure: true,
			sameSite: "lax",
			expires: expiration,
		});
	};

	function handleSave()
	{
		let cookie = {
			marketing,
			personalization,
			analytics,
		};

		let expiration = new Date();
		expiration.setDate(expiration.getDate() + 365);

		setCookie("maethril_consent", cookie, {
			path: "/",
			secure: true,
			sameSite: "lax",
			expires: expiration,
		});
	}

	return (
		<Box
			position="fixed"
			bottom={{ base: "0", md: "60px" }}
			left="0"
			right="0"
			mx={{ base: "0", md: "20px" }}
			w={{ base: "100%", md: "calc(100% - (2 * 20px))" }}
			zIndex={1}>
			<FormControl>
				<Flex
					borderRadius={{ base: "0", md: "md" }}
					borderTopLeftRadius={{ base: "md" }}
					borderTopRightRadius={{ base: "md" }}
					overflow="hidden"
					mx="auto"
					p="20px"
					maxWidth="1380px"
					minWidth="280px"
					direction={{ base: "column", md: "row" }}
					bg="black"
					color="white">
					<Flex direction="column">
						<Text mb="4px">
							This website stores data such as cookies to enable essential site functionality,
							as well as marketing, personalization, and analytics.
							You may change your settings at any time or accept the default settings.
						</Text>
						<HStack
							mb="8px"
							spacing={4}
							fontWeight="600">
							<Link to="https://blog.maethril.net/cookie-notice">Cookie Notice</Link>
							<Link to="https://blog.maethril.net/privacy-notice">Privacy Notice</Link>
						</HStack>
						<Flex
							direction={{ base: "column", sm: "row" }}
							mt="8px">
							{
								props.showMarketing ? (
									<Flex
										alignItems="center"
										mb={{ base: 4, sm: 0 }}
										mr={{ base: 0, sm: 4 }}>
										<Switch
											id="marketingCookies"
											isChecked={marketing}
											onChange={() => setMarketing(!marketing)}
											mr="8px" />
										<FormLabel m="0" htmlFor="marketingCookies">Marketing</FormLabel>
									</Flex>
								) : null
							}
							{
								props.showPersonalization ? (
									<Flex
										alignItems="center"
										mb={{ base: 4, sm: 0 }}
										mr={{ base: 0, sm: 4 }}>
										<Switch
											id="personalizationCookies"
											isChecked={personalization}
											onChange={() => setPersonalization(!personalization)}
											mr="8px" />
										<FormLabel m="0" htmlFor="personalizationCookies">Personalization</FormLabel>
									</Flex>
								) : null
							}
							{
								props.showAnalytics ? (
									<Flex
										alignItems="center"
										mb={{ base: 4, sm: 0 }}
										mr={{ base: 0, sm: 4 }}>
										<Switch
											id="analyticsCookies"
											isChecked={analytics}
											onChange={() => setAnalytics(!analytics)}
											mr="8px" />
										<FormLabel m="0" htmlFor="analyticsCookies">Analytics</FormLabel>
									</Flex>
								) : null
							}
						</Flex>
					</Flex>
					<Flex
						direction="column"
						mt={{ base: "20px", md: "unset" }}
						minWidth="200px"
						justifyContent="center"
						alignItems="center">
						<Button
							onClick={handleAcceptAll}
							h="50%"
							w="100%"
							minH="32px"
							maxH="48px"
							bg="blue.300"
							color="black"
							mb="12px"
							transition="200ms ease"
							_hover={{ bg: "blue.500" }}>
							Accept all
						</Button>
						<Button
							onClick={handleSave}
							h="50%"
							w="100%"
							minH="32px"
							maxH="48px"
							bg="blue.300"
							color="black"
							opacity="0.85"
							transition="200ms ease"
							_hover={{ bg: "blue.500" }}>
							Save
						</Button>
					</Flex>
				</Flex>
			</FormControl>
		</Box>
	);
};

export default CookieConsent;
